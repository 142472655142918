import { Box, ButtonLink } from '@clutter/clean';
import React from 'react';
import { Header } from './header';

export const App: React.FC = () => (
  <>
    <Header showLogout={true} />
    <Box textAlign="center" padding="150px">
      <ButtonLink href="/book_move" size="large" kind="primary">
        Book A Move
      </ButtonLink>
    </Box>
  </>
);
