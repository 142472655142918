import { Box, COLORS, TextButton, transparentize } from '@clutter/clean';
import React from 'react';
import { Logo } from '@portal/components/shared/logo';

export const Header: React.FC<{ showLogout?: boolean }> = ({ showLogout }) => (
  <Box width="100%" height="120px" boxShadow={`0 2px 20px ${transparentize(COLORS.panther, 0.1)}`}>
    <Box.Flex flexDirection="row" justifyContent="space-between" padding="45px">
      <Logo />
      {showLogout && (
        <TextButton size="medium" href="/logout">
          Logout
        </TextButton>
      )}
    </Box.Flex>
  </Box>
);
