import { Box, Button, ButtonLink, COLORS, Input, Label } from '@clutter/clean';
import axios from 'axios';
import React, { useState } from 'react';
import { Header } from './header';

export const Login: React.FC = () => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const handleSubmit = async () => {
    setLoading(true);

    if (!email || !password) {
      setError('Please enter your login credentials to continue.');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post('/login.json', { partner_user: { email, password, remember_me: 1 } });
      window.location.replace(response.data.location);
    } catch {
      setError("We couldn't find a user with that email and password.");
    }
    setLoading(false);
  };

  return (
    <>
      <Header showLogout={false} />
      <Box margin="auto" width="600px" padding="75px">
        <Box.Flex flexDirection="column">
          <Label>Email</Label>
          <Input placeholder="Email" type="email" value={email} onChange={(event) => setEmail(event.target.value)} />
          <Label>Password</Label>
          <Input
            placeholder="Password"
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </Box.Flex>
        <Box margin="24px 0">
          <Button fullWidth kind="primary" size="medium" loading={loading} onClick={handleSubmit}>
            Log in
          </Button>
        </Box>
        <Box margin="24px 0">
          <ButtonLink fullWidth href="/password/new">
            Reset Password
          </ButtonLink>
        </Box>
        {error && (
          <Box textAlign="center" padding="12px" background={COLORS.flower}>
            {error}
          </Box>
        )}
      </Box>
    </>
  );
};
