import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { Layout } from '@clutter/clean';
import * as React from 'react';
import ReactRailsUJS from 'react_ujs';
import { Global, css } from '@emotion/react';
import { App } from './components/app';
import { Login } from './components/login';

const GLOBAL_STYLES = css`
  body {
    margin: 0;
  }
`;

const getDisplayName = <P extends Record<string, unknown>>(Component: React.ComponentType<P>): string =>
  Component.displayName || Component.name || 'Component';

const withLayout = <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.FC<P> => {
  const withLayoutComponent = (props: P) => (
    <Layout>
      <Global styles={GLOBAL_STYLES} />
      <Component {...props} />
    </Layout>
  );
  withLayoutComponent.displayName = `withLayout(${getDisplayName(Component)})`;
  return withLayoutComponent;
};

ReactRailsUJS.getConstructor = (name: string) => {
  switch (name) {
    case 'App':
      return withLayout(App);
    case 'Login':
      return withLayout(Login);
    default:
      throw new Error(`unknown constructor for ${name}`);
  }
};
