import { COLORS } from '@clutter/clean';
import React from 'react';

export const Logo: React.FC<{
  color?: string;
}> = ({ color = COLORS.tealPrimary }) => (
  <svg width="96" height="24" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={color}
      fillRule="evenodd"
      d="M90.089 10.467C90.4 9.667 91.956 6.689 96 7v4.044c-5.289 0-5.911 5.067-5.911 5.067v6.845h-4.356V7.8l4.356-.667v3.334zm-13.778 9.6c1.822 0 3.6-.445 5.156-2.134l1.6 2.623c-1.467 1.51-3.734 2.755-7.29 2.755-4.133 0-7.51-2.578-7.51-8.178 0-5.244 3.066-8.266 7.91-8.266 4.979 0 7.29 2.933 7.29 6.977 0 1.245-.223 2.178-.223 2.178H72.8c.267 2.622 1.422 4.045 3.511 4.045zm0-10.134c-2.044 0-3.155 1.334-3.422 3.734h6.578c.044-2.49-1.067-3.734-3.156-3.734zm-12 9.6c.756 0 2.045-.533 2.445-.71l1.555 3.2c-.978.444-2.978 1.244-5.067 1.244-3.022 0-4.577-1.645-4.577-5.334v-7.6H52v7.69c0 1.333.667 1.51 1.333 1.51.756 0 2.4-.666 2.8-.844l1.556 3.2c-.978.444-3.333 1.378-5.422 1.378-3.023 0-4.578-1.645-4.578-5.334v-7.6H45.51v-3.2h2.178v-3.91L52 2.6v4.533h6.667v-3.91l4.31-.623v4.533h5.29c.222 0 .355.09.222.4-.133.311-1.333 2.8-1.333 2.8h-4.178v7.69c0 1.333.666 1.51 1.333 1.51zM39.156 17.89V7.133h4.31v15.823h-4.31v-1.912c-1.423.978-3.645 2.223-6.178 2.223-3.422 0-4.711-2.045-4.711-4.978V7.133h4.355v10.178c0 1.467.4 2.578 2.045 2.578 1.6 0 3.51-1.111 4.489-2zm-17.69 5.067V1.666L25.823 1v21.956h-4.355zm-9.955-3.823c-4.4 0-6.933-3.6-6.933-6.755 0-4.222 3.378-6.845 6.889-6.845.355 0 .755.045 1.155.09.178.044.267 0 .267 0s-.089.177-.089.355c0 1.689 1.2 2.933 2.933 2.933 1.69 0 2.934-1.2 2.934-2.933 0-.8-.534-3.067-5.378-4.267a12.36 12.36 0 0 0-2.311-.222c-1.778 0-3.734.444-5.467 1.378A10.911 10.911 0 0 0 0 12.377a10.925 10.925 0 0 0 10.933 10.934c3.423 0 6.49-1.555 8.49-4.044l-1.956-3.023c0-.044-2.667 2.89-5.956 2.89z"
    />
  </svg>
);
